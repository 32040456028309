import { Link, useNavigate } from '@solidjs/router';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import myContentStore from '../../../stores/my-content-store';
import theme from '../../../style/theme';
import { GlobeIcon } from '../../icons-library/globe';
import { MagnifyingGlassIcon } from '../../icons-library/magnifying-glass';
import { ShoppingBasketIcon } from '../../icons-library/shopping-basket';
import { StarIcon } from '../../icons-library/star';
import { localeMenuIsOpen, toggleCountrySelectionMenu } from '../../../stores/locale-store';
import { LoginControls } from '../../login-controls/login-controls';
import { StyledIcon } from '../header.style';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';

export const HeaderHcpNavigationShortcuts = () => {
    const { menuStore, viewport, searchPage, shopPage, pageRegistration, myContentPage, siteInfo, localize } = useContext(AppContext);
    const { close } = menuStore;
    const { myContentHasItems } = myContentStore;
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;
    const navigate = useNavigate();

    return (
        <>
            <Show when={pageRegistration.url && pageRegistration.url !== ''}>
                <StyledIcon>
                    <LoginControls />
                </StyledIcon>
            </Show>

            <Show when={!siteInfo?.siteType || siteInfo.siteType === 'default'}>
                <Show when={myContentPage.url && myContentPage.url !== ''}>
                    <StyledIcon>
                        <button 
                            onClick={() => {
                                close();
                                navigate(`${removeTrailingSlash(myContentPage.url)}`);
                            }}
                        >
                            <StarIcon
                                altText={localize('header_my_content_icon', 'Link to My content')}
                                aria-label={localize('header_my_content_icon', 'Link to My content')}
                                fill={theme.palette[myContentHasItems() ? 'red' : 'darkGray']}
                                {...(viewport.width < theme.breakpoints.MOBILE
                                    ? {
                                        height: 2.5,
                                        width: 2.5,
                                        opacity: 0.7,
                                    }
                                    : {})}
                            />
                        </button>
                    </StyledIcon>
                </Show>

                <Show when={shopPage.url && shopPage.url !== '' && !isMobile()}>
                    <StyledIcon>
                        <Link
                            href={removeTrailingSlash(shopPage.url)}
                            style={{ display: 'flex', 'align-items': 'center' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ShoppingBasketIcon
                                altText={localize('header_shop_icon', 'Link to Shop')}
                                aria-label={localize('header_shop_icon', 'Link to Shop')}
                                {...(viewport.width < theme.breakpoints.MOBILE
                                    ? {
                                        height: 2.5,
                                        width: 2.5,
                                        opacity: 0.7,
                                    }
                                    : {})}
                            />
                        </Link>
                    </StyledIcon>
                </Show>
            </Show>

            <Show when={searchPage?.url && searchPage.url !== ''}>
                <StyledIcon onClick={close}>
                    <button
                        onClick={() => {
                            close();
                            navigate(`${removeTrailingSlash(searchPage.url)}`);
                        }}
                    >
                        <MagnifyingGlassIcon
                            altText={localize('header_search_icon', 'Link to Search page')}
                            aria-label={localize('header_search_icon', 'Link to Search page')}
                            {...(viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2.5,
                                    width: 2.5,
                                    opacity: 0.7,
                                }
                                : {})}
                        />
                    </button>
                </StyledIcon>
            </Show>

            <Show when={!isMobile() && (!siteInfo?.siteType || siteInfo?.siteType === 'default')}>
                <StyledIcon
                    isActive={localeMenuIsOpen()}
                >
                    <button
                        onClick={() => {
                            close();
                            toggleCountrySelectionMenu();
                        }}
                        tabIndex="0"
                    >
                        <GlobeIcon
                            altText={localize('header_country_selection_icon', 'Open country selection menu')}
                            aria-label={localize('header_country_selection_icon', 'Open country selection menu')}
                            {...(viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2.5,
                                    width: 2.5,
                                    opacity: 0.7,
                                }
                                : {})}
                        />
                    </button>
                </StyledIcon>
            </Show>
        </>
    );
};
