import { For, Show, createEffect, createSignal, useContext, createMemo } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { gridSettings, StyledSidebarContainer, StyledSidebarContainerLine, StyledSidebar, StyledSidebarInner } from '../../ui-components/layouts/sidebar.style';
import { StyledFilterHeadingContainer, StyledProductsContainer } from '../products/products.style';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';
import { DocumentHolder, Type, Category } from './document-holder';
import { StyledClearAllContainer, StyledDocument, StyledDocumentRow, StyledDocumentsList, StyledHeadingWrapper, StyledSearchInput } from './files.style';
import { Button } from '../../ui-components/button/button';
import { MobileFilesFilter } from './mobile-files-filter';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { gql } from 'graphql-request';


const GET_FILES_QUERY = gql`
    query GetFiles($typeIds: [ID!]) {
        documentHolders: getDocumentHoldersByTypes(typeIds: $typeIds) {
            id
            uuid
            name
            slug
            identifierId
            categories {
                id
                uuid
                name
                parentId
            }
            files {
                name
                path
            }
            type {
                id
                name
                uuid
            }
        }
    }
`;


const getTypesFromDocumentHolders = (documentHolders: DocumentHolder[]) => {
    const types = documentHolders.map((documentHolder) => {
        return documentHolder.type;
    });
    // Remove duplicates before returning.
    return types.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id && t.name === value.name
        ))
    );
};


export const Files = (props: FilesProps) => {
    const { viewport, localize, createCachedResource } = useContext(AppContext);
    const [isTabletOrSmaller, setIsTabletOrSmaller] = createSignal<boolean>(false);
    const [isMobile, setIsMobile] = createSignal<boolean>(false);
    const [searchQuery, setSearchQuery] = createSignal('');
    const [parentCategories, setParentCategories] = createSignal<Category[]>([]);
    const [subCategories, setSubCategories] = createSignal<Category[]>([]);
    const [typeIds, setTypeIds] = createSignal(props.types ? [...props.types] : []);


    const [ documentHoldersData ] = createCachedResource(
        GET_FILES_QUERY,
        () => ({
            typeIds: typeIds(),
        }),
        true
    );

    createEffect(() => {
        const newTypeIds = props.types ? [...props.types] : [];
        setTypeIds(newTypeIds);
    });

    const documentHolders = createMemo<DocumentHolder[]>(() => documentHoldersData()?.documentHolders || []);
    const types = createMemo<Type[]>(() => getTypesFromDocumentHolders(documentHolders()));


    // SELECTED States
    const [selectedTypeId, setSelectedTypeId] = createSignal<number>();
    const [selectedParentCategoriesIds, setSelectedParentCategoriesIds] = createSignal<number[]>([]);
    const [selectedSubCategoriesIds, setSelectedSubCategoriesIds] = createSignal<number[]>([]);

    createEffect(() => setIsTabletOrSmaller(viewport.width <= theme.breakpoints.TABLET));
    createEffect(() => setIsMobile(viewport.width <= theme.breakpoints.MOBILE));

    const clearFilters = (e: any) => {
        e.preventDefault();
        setSelectedTypeId();
        setSelectedParentCategoriesIds([]);
        setSelectedSubCategoriesIds([]);

        setParentCategories([]);
        setSubCategories([]);
    };

    const handleTypeClick = (type: Type) => {
        setSelectedTypeId(type.id);
        // Reset rest of the state
        setSubCategories([]);
        setSelectedParentCategoriesIds([]);
        setSelectedSubCategoriesIds([]);

        // Extract top parent categories from filtered documents
        const categories = filteredDocuments().flatMap(doc => doc.categories?.filter(cat => !cat.parentId));
        const uniqueTopParentCategories = [...new Map(categories.map(item => [item['id'], item])).values()];

        // Set the parent categories for the documents with the clicked type.
        setParentCategories(uniqueTopParentCategories);
    };


    const handleCategoryClick = (category: Category) => {
        setSelectedParentCategoriesIds(prevSelected => {
            if (prevSelected.includes(category.id)) {
                return prevSelected.filter(id => id !== category.id);
            } else {
                return [...prevSelected, category.id];
            }
        });

        // Filter child categories based on selected parent categories
        const filteredChildCategories = documentHolders().flatMap(doc => doc.categories?.filter((cat) => {
            if (doc.type?.id !== selectedTypeId()) {
                return false;
            }
            return selectedParentCategoriesIds().some((selectedCategoryId) => {
                if (cat.parentId === selectedCategoryId) {
                    return true;
                }
                return false;
            });
        }));

        const uniqueChildCategories = [...new Map(filteredChildCategories.map(item => [item['id'], item])).values()];
        setSubCategories(uniqueChildCategories);
    };

    const handleSubCategoryClick = (category: Category) => {
        setSelectedSubCategoriesIds(prevSelected => {
            if (prevSelected.includes(category.id)) {
                return prevSelected.filter(id => id !== category.id);
            } else {
                return [...prevSelected, category.id];
            }
        });

    };

    const filteredDocuments = () => {
        const documents = documentHolders().filter((documentHolder) => {

            // First, if there is a search query active, make sure it's matching.
            if (searchQuery().length) {
                const nameMatch = documentHolder.name.toLocaleLowerCase().includes(searchQuery());
                const categoryMatch = documentHolder.categories.some( (cat) => cat.name.toLocaleLowerCase().includes(searchQuery()) );
                if (!nameMatch && !categoryMatch) {
                    return false;
                }
            }

            // If they dont have a filter menu we show every document.
            if (!props.showFilterMenu) {
                return true;
            }

            // If the user hasn't selected any type, show all documents.
            if (!selectedTypeId()) {
                return true;
            }

            // Then, make sure it has the same type.
            if (documentHolder.type?.id !== selectedTypeId()) {
                return false;
            }

            // Then, if no top category has been selected, show it.
            if (selectedParentCategoriesIds().length === 0) {
                return true;
            }

            // If categories has been selected, make sure it has at least one matching category with the selected ones.
            const hasAnySelectedCategory = documentHolder.categories.some((category) => selectedParentCategoriesIds().includes(category.id));
            if (!hasAnySelectedCategory) {
                return false;
            }

            // Then, IF any subCategories has been selected, make sure it has at least one of those.
            if (selectedSubCategoriesIds().length) {
                const hasAnySelectedChildCategory = documentHolder.categories.some((category) => selectedSubCategoriesIds().includes(category.id));
                if (!hasAnySelectedChildCategory) {
                    return false;
                }
            }

            return true;
        });
        return documents;
    };

    const handleSearchInput = (e: any) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    if (types()?.length > 0) {
        handleTypeClick(types()[0]);
    }

    return (
        <ErrorCatcher componentName='Files'>
            <Show when={!documentHoldersData.loading} fallback={<LoadingPlaceHolder />}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 2rem; padding-bottom: 2rem;'}
                >
                    <Grid {...props.showFilterMenu ? {...gridSettings.container } : { templateShorthand: [12] }}>
                        <Show when={!isTabletOrSmaller() && props.showFilterMenu}>
                            <StyledSidebarContainer>
                                <StyledSidebarContainerLine>
                                    <StyledSidebar>
                                        <StyledSidebarInner>
                                            <StyledFilterHeadingContainer>
                                                <Heading tag='h2' variant='medium' noBlockSpacing={true}>{localize('filters', 'Filters')}</Heading>
                                            </StyledFilterHeadingContainer>

                                            <FilterList listHeading={localize('type', 'Type')}>
                                                <For each={types()}>{(type: Type) => (
                                                    <li>
                                                        <Radio
                                                            value={type.uuid}
                                                            whenClicked={() => handleTypeClick(type)}
                                                            name='type'
                                                            readableName={localize(type.name, type.name)}
                                                            isChecked={selectedTypeId() === type.id}
                                                        />
                                                    </li>
                                                )}</For>
                                            </FilterList>

                                            <Show when={parentCategories().length}>
                                                <FilterList listHeading={localize('category', 'Category')}>
                                                    <For each={parentCategories()}>{(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category?.uuid}
                                                                whenClicked={() => handleCategoryClick(category)}
                                                                name={localize(category?.name, category.name)}
                                                                isChecked={selectedParentCategoriesIds().includes(category?.id)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>

                                            <Show when={subCategories().length}>
                                                <FilterList listHeading={localize('sub-category', 'Sub Category')}>
                                                    <For each={subCategories()}>{(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category.uuid}
                                                                whenClicked={() => handleSubCategoryClick(category)}
                                                                name={localize(category.name, category.name)}
                                                                isChecked={selectedSubCategoriesIds().includes(category.id)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>

                                            <Show when={selectedTypeId()}>
                                                <StyledClearAllContainer>
                                                    <Button
                                                        label={localize('clear-all', 'Clear all')}
                                                        onClick={(e) => clearFilters(e)}
                                                        variant='tertiary'
                                                        noCaps={true}
                                                    />
                                                </StyledClearAllContainer>
                                            </Show>

                                        </StyledSidebarInner>
                                    </StyledSidebar>
                                </StyledSidebarContainerLine>
                            </StyledSidebarContainer>
                        </Show>

                        <StyledProductsContainer>
                            <StyledHeadingWrapper>
                                <Heading tag='h2' variant='xxlarge'>
                                    { props.headline }
                                </Heading>
                            </StyledHeadingWrapper>

                            <StyledVerticalSpace size={3} />

                            <StyledFlexRow justifyContent={isMobile() ? 'center' : 'start'}>
                                <StyledSearchInput
                                    oninput={(e: any) => handleSearchInput(e)}
                                    placeholder={localize('search', 'Search...')}
                                />
                            </StyledFlexRow>

                            <StyledVerticalSpace size={2} />

                            <Show when={isTabletOrSmaller() && props.showFilterMenu}>
                                <MobileFilesFilter
                                    clickHandlers={{
                                        handleTypeClick,
                                        handleCategoryClick,
                                        handleSubCategoryClick,
                                    }}

                                    selectedTypeId={selectedTypeId()}
                                    selectedParentCategoriesIds={selectedParentCategoriesIds()}
                                    selectedSubCategoriesIds={selectedSubCategoriesIds()}

                                    types={types()}
                                    parentCategories={parentCategories()}
                                    subCategories={subCategories()}

                                    clearFilters={clearFilters}
                                />

                                <Show when={selectedTypeId()}>
                                    <StyledClearAllContainer>
                                        <Button
                                            label={localize('clear-all', 'Clear all')}
                                            onClick={(e) => clearFilters(e)}
                                            variant='tertiary'
                                            noCaps={true}
                                        />
                                    </StyledClearAllContainer>
                                </Show>
                            </Show>


                            <StyledDocumentsList>
                                <For each={filteredDocuments()}>
                                    {(documentHolder: DocumentHolder) => (
                                        <StyledDocumentRow>
                                            <StyledDocument href={`/downloads/${documentHolder.id}/${documentHolder.slug}`} target="_blank">
                                                {documentHolder.name}
                                            </StyledDocument>
                                        </StyledDocumentRow>
                                    )}
                                </For>
                            </StyledDocumentsList>
                        </StyledProductsContainer>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

export type FilesProps = {
    types: string[];
    headline: string;
    showFilterMenu: string;
}
