import clsx from 'clsx';
import { For, Show, createSignal, onCleanup, onMount } from 'solid-js';
import { ChevronUpBoldIcon } from '../../icons-library/chevron-up-bold';
import { PrimaryMenuData } from '../../menu/types/primary-menu-types';
import urlMatches from '../../../tools/url-match';
import removeAmpersand from '../../../tools/remove-ampersand';
import { StyledButton, StyledDropDown, StyledDropDownItem, StyledDropdownLink, StyledDropdownMenuNav, StyledLinkContent, StyledLinkSpan } from './hcp-header-dropdown-menu.style';

type HeaderAtosCareDropdownMenuProps = {
    primaryMenuData: PrimaryMenuData;
    floatHeader: boolean;
};

export const HeaderHcpDropdownMenu = (props: HeaderAtosCareDropdownMenuProps) => {
    const [activeSubmenu, setActiveSubmenu] = createSignal(0);

    const transitionClasses = 'transition-color ease-in-out duration-200';

    const handleOnClick = (e: Event, ID: number) => {
        e.stopImmediatePropagation();
        setActiveSubmenu(activeSubmenu() === ID ? 0 : ID);
    };

    let dropdownMenuRef: HTMLDivElement | undefined;
    const handleOutsideClick = () => {
        const handleClickOutside = (event: Event) => {
            if (!dropdownMenuRef?.contains(event.target as Node) && activeSubmenu() !== 0) {
                setActiveSubmenu(0);
            }
        };

        window.addEventListener('click', handleClickOutside);

        onCleanup(() => {
            window.removeEventListener('click', handleClickOutside);
        });
    };

    onMount(() => {
        handleOutsideClick();
    });

    const closeSubmenues = () => setActiveSubmenu(0);

    return (
        <StyledDropdownMenuNav floatHeader={props.floatHeader}>
            <For each={props.primaryMenuData?.menu}>
                {(item, index) => (
                    <Show
                        fallback={
                            <StyledDropdownLink href={item.url} floatHeader={props.floatHeader}>
                                <StyledLinkSpan 
                                    active={urlMatches(item.url)}
                                    floatHeader={props.floatHeader}
                                >
                                    {removeAmpersand(item.title)}
                                </StyledLinkSpan>
                            </StyledDropdownLink>
                        }
                        when={item.children}
                    >
                        <div class="relative">
                            <StyledButton
                                aria-label={(activeSubmenu() === item.ID ? 'Hide' : 'Show') + ' submenu for ' + item.title}
                                data-index={index()}
                                onClick={(e) => handleOnClick(e, item.ID)}
                                active={item?.children?.some((child: any) => urlMatches(child.url)) || item.ID === activeSubmenu()}
                                floatHeader={props.floatHeader}
                            >
                                {removeAmpersand(item.title)}
                                <span
                                    class={clsx('ml-[0.5rem]', 'transform', 'translate-y-[1px] transform transition-transform duration-200 origin-center', {
                                        'rotate-180': activeSubmenu() === item.ID,
                                    })}
                                >
                                    <ChevronUpBoldIcon />
                                </span>
                            </StyledButton>

                            <Show when={item.ID === activeSubmenu()}>
                                <div class="sub-menu absolute animate-fade-in-move-up mt-[0.5rem]" ref={dropdownMenuRef}>
                                    <StyledDropDown class="shadow-subtle text-left translate-x-5 text-[1.125rem] min-w-[25rem] rounded-[0.9rem] bg-white px-3 text-black">
                                        <For each={item?.children}>
                                            {(subItem: any) => (
                                                <li>
                                                    <StyledDropDownItem 
                                                        active={false} 
                                                        class={clsx(transitionClasses)}
                                                        href={subItem.url}
                                                        onClick={closeSubmenues}
                                                        floatHeader={props.floatHeader}
                                                    >
                                                        <StyledLinkContent active={urlMatches(subItem.url)}>{removeAmpersand(subItem.title)}</StyledLinkContent>
                                                    </StyledDropDownItem>
                                                </li>
                                            )}
                                        </For>
                                    </StyledDropDown>
                                </div>
                            </Show>
                        </div>
                    </Show>
                )}
            </For>
        </StyledDropdownMenuNav>
    );
};
