import { Show, useContext } from 'solid-js';
import { styled } from 'solid-styled-components';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import { containerQueries } from '../../tools/check-container-support';
import { ErrorCatcher } from '../../tools/error-catcher';
import { sidebarPaddingRight, StyledSidebar, StyledSidebarContainer, StyledSidebarInner } from '../../ui-components/layouts/sidebar.style';
import { SidebarMenuDesktop } from '../sidebar/desktop/sidebar-menu-desktop';
import { SidebarMenuMobile } from '../sidebar/mobile/sidebar-menu-mobile';
import { StyledSidebarContainerLineHCP } from './page.styles';
import { Text } from '../../ui-components/text/text';
import { Column } from '../../grid-system/column/column';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';

const StyledSidebarWrapper = styled.div`
    ${containerQueries('tabletMin')} {
        padding-left: ${sidebarPaddingRight}rem;
    }
`;

type SidebarPageProps = {
    content: any;
    hasHero: boolean;
    mcRef?: string;
    isHcp: boolean;
};

export const SidebarPage = (props: SidebarPageProps) => {
    const { viewport } = useContext(AppContext);

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    const content = () => {
        const blocks = props.content?.blocks;

        if (!props.hasHero) {
            return blocks;
        }

        if (blocks?.length < 2) {
            return blocks;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [hero, ...rest] = blocks;

        if (!rest) {
            return;
        }

        return rest;
    };

    return (
        <ErrorCatcher componentName="SidebarPage">
            <SidebarMenuMobile />
            <Show when={props.hasHero}>
                <DynamicModuleGenerator content={[props.content?.blocks[0]]} />
            </Show>
            <Section 
                templateShorthand={[12]}
                widthType="bgFull"
                customCss={`
                    padding-top: 0;
                    padding-bottom: 0;
                    ${props.isHcp && !props.hasHero && !isOriginalTablet() ? 'padding-top: 6rem;' : ''}
                `}
            >
                <Grid
                    templateShorthand={[3, 9]}
                    responsive={{
                        largeDesktop: [3, 9],
                        smallDesktop: [3, 9],
                        tablet: [12],
                        mobile: [12],
                    }}
                >
                    <StyledSidebarContainer>
                        <StyledSidebarContainerLineHCP>
                            <StyledSidebar>
                                <StyledSidebarInner>
                                    <SidebarMenuDesktop />
                                </StyledSidebarInner>
                            </StyledSidebar>
                        </StyledSidebarContainerLineHCP>
                    </StyledSidebarContainer>
                    <Grid inheritParentGrid={true} templateShorthand={[10]} customCss={'container-type: inline-size;'}>
                        <StyledSidebarWrapper>
                            <Show when={content()}>
                                <DynamicModuleGenerator
                                    content={content()}
                                    permalink={props.content?.page?.content.permalink}
                                    pageTitle={props.content?.page?.content.post_title}
                                />
                                <Show when={props.mcRef}>
                                    <Section 
                                        templateShorthand={[ 10 ]}
                                        widthType='bgFull'
                                        isLayoutSection={false}
                                    >
                                        <Column>
                                            <Text fontSize='smaller'>
                                                {props.mcRef}
                                            </Text>
                                        </Column>
                                    </Section>
                                </Show>
                            </Show>
                        </StyledSidebarWrapper>
                    </Grid>
                </Grid>
            </Section>
        </ErrorCatcher>
    );
};
